import React from "react"
import Balls from "../../images/bb-bg.png"
import "./styles.css"
import { useAnalytics } from "../../hooks/useTracking"
import { openHubspotChat } from "../../utils"

export const BugBounty = () => {
  const analytics = useAnalytics()
  return (
    <section
      data-sal="slide-up"
      data-sal-delay="100"
      data-sal-easing="ease"
      className="bugbounty-section"
    >
      <img src={Balls} className="bugbounty-balls" />
      <div className="container">
        <div className="bugbounty-content">
          <h2 className="bugbounty-heading">Bug Bounty Plan</h2>
          <h3 className="bugbounty-description">
            If security is your top priority, this plan is your ultimate
            solution to proactively protect your systems.
          </h3>
          <h4 className="bugbounty-included">What’s included:</h4>
          <ul className="bugbounty-list">
            <li>All features in Professional</li>
            <li>Real-time dashboards</li>
            <li>Customizable reward tiers</li>
            <li>Vulnerability triage and prioritization</li>
            <li>Self-managed setup and management for 1 domain</li>
            <li>Access to 2,000+ ethical hackers / bug hunters</li>
          </ul>
          <button
            onClick={() => {
              analytics.bugBounty()
              openHubspotChat()
            }}
            className="wt-button wt-button--red-blue bugbounty-btn"
          >
            Get in Touch to Launch
          </button>
        </div>
      </div>
    </section>
  )
}
